import Vue from 'vue'
import {EagleListConfigInterface, TableDataType} from '@/components/list/types/list'
import siteAdminListConfig from 'modules/siteAdmin/views/siteAdminListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardSiteAdminListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardSiteAdminListConfig = siteAdminListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    const table = this.standardSiteAdminListConfig.table
    // @ts-ignore
    table.data = table.data.filter(tableItem => tableItem.key != 'locked')

    return {
      search: this.standardSiteAdminListConfig.search,
      filter: this.standardSiteAdminListConfig.filter,
      selectedData: this.standardSiteAdminListConfig.selectedData,
      displayMode: this.standardSiteAdminListConfig.displayMode,
      displayModeTypes: this.standardSiteAdminListConfig.displayModeTypes,
      sort: this.standardSiteAdminListConfig.sort,
      card: this.standardSiteAdminListConfig.card,
      table: table,
    }
  }
}

export default new listConfig()
